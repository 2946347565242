// 资产打印->资产打码的table表格ts数据组件
// EnumColumnUse,表格后方的显示(新增,删除,查看,修改....)

import { toRefs } from "vue";
import { ColumnProps, EnumColumnUse } from "@/components";
import { IYYResponse } from "@/ts/type/system";
import {
  RList,
  RListData,
  getCardList,
  RPageableSearch,
  IPage,
  ICardInfo,
} from "@/ts";
import md5 from 'js-md5';
// // 获得资产分类树
// const getAssetClassTree = () => {
//   return <Promise<IYYResponse<any>>>{};
// };

// // 获得部门树
// const getDepartmentTree = () => {
//   return <Promise<IYYResponse<any>>>{};
// };

// // 获得存放地点
// const getCFDD = () => {
//   console.log(">>>>>>");
//   return <Promise<IYYResponse<any>>>{};
// };

// // 获得使用人
// const getPersonTree = () => {
//   return <Promise<IYYResponse<any>>>{};
// };

const config: RList<ICardInfo, RPageableSearch> = {
  title: "资产打码",
  // 资产打码-列表数据接口
  requestApi: async (param: RPageableSearch): Promise<RListData<ICardInfo>> => {
    console.log(param, 'paramparamparamparam');
    // param.pageSize=20
    let datas: any = {
      pageNumber: param.pageNum,
      // pageSize: param.pageSize,
      ...param
    }
    datas.SHIYONGRID = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : null
    datas.SHIYONG_GLBMID = sessionStorage.getItem('deptId') ? sessionStorage.getItem('deptId') : null
    datas.ZICHANFLID = sessionStorage.getItem('zichanId') ? sessionStorage.getItem('zichanId') : null
    
    delete datas.SHIYONGRNAME
    delete datas.SHIYONG_GLBMNAME
    delete datas.ZICHANFLNAME
    delete datas.pageNum
    const data = await getCardList(datas);
    // param.pageSize=20
    // const data = await getCardList(param);
    data.content.forEach((item: any, index: any) => {
      if (item.QUDERQ) {
        item.QUDERQ = item.QUDERQ.substr(0, item.QUDERQ.lastIndexOf('T'))
      }
      if (item.GS1) {
        item.EPC = md5(item.GS1).toUpperCase()
      }
    })
    console.log(data.content, 'data.contentdata.contentdata.contentdata.content');
    return {
      list: data.content,//列表数据
      pageNum: data.number,//分页
      pageSize: data.numberOfElements,//分页
      total: data.totalElements,//总数量
    };
  },
  // // 列表数据接口
  // requestApi:(param:RPageableSearch)=>{
  //     console.log('>>>>>', param);
  //     return getCardList({
  //         pageNumber:param.pageNum,
  //         pageSize:param.pageSize
  //     });
  // },
  // // 输出转换回掉接口
  // dataCallback:(data:IPage<ICardInfo[]>):RListData<ICardInfo>=>{
  //     return <RListData<ICardInfo>>{
  //         list:data.content,
  //         pageNum:data.number,
  //         pageSize:data.numberOfElements,
  //         total:data.totalElements
  //     };
  // },
  selectId: "OLDID",
  /*
    // 表格列信息
    prop:定义的键值,type:类型,fixed:固定,width:宽度,use:按钮(新增,删除,查看,修改....)
  */
  columns: [
    {
      prop: "OLDID", type: "selection", fixed: "left", width: 55,
      use: EnumColumnUse.LIST,
    },
    { type: "index", label: "#", width: 80, use: EnumColumnUse.LIST },
    // { type: "expand", label: "Expand", width: 100, use: EnumColumnUse.LIST },

    {
      prop: "ZICHANBH", columnKey: "ZICHANBH", label: "资产编码", width: "180", el: "input",
      search: {},
      use: EnumColumnUse.LIST | EnumColumnUse.SEARCH,
    },
    {
      prop: "GS1", columnKey: "GS1", label: "GS1编码", width: "210",
      // search: {},
      use: EnumColumnUse.LIST,
    },
    {
      prop: "ZICHANMC", columnKey: "ZICHANMC", label: "资产名称", width: "150", el: "input",
      search: {},
      use: EnumColumnUse.LIST | EnumColumnUse.SEARCH,
    },
    {
      prop: "ZICHANFLNAME", columnKey: "ZICHANFLID", label: "资产分类", width: "150", el: "input",
      search: {props:{placeholder:"输入后跳出资产分类弹框"}},
      // fieldNames: { label: "genderLabel", value: "genderValue" },
      use: EnumColumnUse.LIST | EnumColumnUse.SEARCH,
    },
    {
      prop: "SHIYONG_GLBMNAME", columnKey: "SHIYONG_GLBMID", label: "部门", width: "110", el: "input",
      search: {props:{placeholder:"输入后跳出部门弹框"}},
      fieldNames: { label: "genderLabel", value: "genderValue" },
      use: EnumColumnUse.LIST | EnumColumnUse.SEARCH,
    },
    {
      prop: "CUNFANGDDID", columnKey: "CUNFANGDDID", label: "存放地点", width: "180", el: "input",
      search: {},
      fieldNames: { label: "genderLabel", value: "genderValue" },
      use: EnumColumnUse.LIST | EnumColumnUse.SEARCH,
    },
    // {
    //   prop: "SHIYONGRID", columnKey: "SHIYONGRID", label: "使用人", width: "110", el: "input",
    //   search: {},
    //   fieldNames: { label: "genderLabel", value: "genderValue" },
    //   use: EnumColumnUse.LIST | EnumColumnUse.SEARCH,
    // },
    {
      prop: "SHIYONGRNAME", columnKey: "SHIYONGRID", label: "使用人", width: "110", el: "input",
      search: {props:{placeholder:"输入后跳出使用人弹框"}},
      fieldNames: { label: "genderLabel", value: "genderValue" },
      use: EnumColumnUse.LIST | EnumColumnUse.SEARCH,
    },
    {
      prop: "PINPAI", columnKey: "PINPAI", label: "品牌", width: "110", el: "input",
      use: EnumColumnUse.LIST
    },
    {
      prop: "GUIGEXH", columnKey: "GUIGEXH", label: "型号", width: "150", el: "input",
      search: {},
      use: EnumColumnUse.LIST
    },
    {
      prop: "SHIYONGZKMC", columnKey: "SHIYONGZK2ID", label: "使用状态", width: "110",
      use: EnumColumnUse.LIST,
    },
    {
      prop: "SHULIANG", columnKey: "SHULIANG", label: "数量", width: "60",
      use: EnumColumnUse.LIST,
    },
    {
      prop: "PRICE", columnKey: "PRICE", label: "原值", width: "120",
      use: EnumColumnUse.LIST,
    },
    {
      prop: "EPC", columnKey: "OLDID", label: "EPC内容", width: "320",
      use: EnumColumnUse.LIST,
    },
    {
      prop: "BQLX", columnKey: "BQLX", label: "标签类型", width: "110",
      use: EnumColumnUse.LIST,
    },
    {
      prop: "BEIZHU", columnKey: "BEIZHU", label: "备注", width: "210",
      use: EnumColumnUse.LIST,
    },
    {
      prop: "DAYINCS", columnKey: "DAYINCS", label: "打印次数", width: "110",
      use: EnumColumnUse.LIST,
    },
    {
      prop: "IS_TIEMA", columnKey: "IS_TIEMA", label: "是否贴码", width: "110",
      use: EnumColumnUse.LIST,
    },

    {
      prop: "PANDIANZT", columnKey: "PANDIANZT", label: "盘点状态", width: "110",
      use: EnumColumnUse.LIST,
    },
    {
      prop: "kapianzt", columnKey: "kapianzt", label: "盘点状态", width: "110",
      use: EnumColumnUse.LIST,
    },
    {
      prop: "ZJDYDATE", columnKey: "ZJDYDATE", label: "最近打印时间", width: "140",
      use: EnumColumnUse.LIST
    }
    // { prop: "operation", label: "操作", fixed: "right", width: 80 } 
  ]
};
export default config;